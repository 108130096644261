<template>
  <div>
    <swiper
      :options="swiperOption"
      ref="swiperRef"
      v-if="showNewsFeed"
      class="news-banner-slider"
    >
      <swiper-slide v-for="news in newsFeed" :key="news.newsId">
        <news-banner :news="news" @remove="remove" />
      </swiper-slide>
    </swiper>
  </div>
</template>
<style lang="scss">
.news-banner-slider {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 2000;
}
</style>
<script>
import NewsBanner from "./NewsBanner.vue";

import newsService from "~/service/newsService";
import clickHandler from "~/mixins/clickHandler";

export default {
  name: "NewsBannerSlider",
  components: { NewsBanner },
  mixins: [clickHandler],
  data() {
    return {
      newsFeed: [],
      swiperOption: {
        slidesPerView: 1,
        spaceBetween: 0,
        autoplay: true,
        watchOverflow: true,
        loop: false
      }
    };
  },
  computed: {
    showNewsFeed() {
      return this.newsFeed.length > 0;
    }
  },
  methods: {
    async reload() {
      this.newsFeed = (await newsService.feed()) || [];
    },
    async remove(id) {
      this.newsFeed = this.newsFeed.filter(item => item.newsId != id);
    }
  },

  mounted() {
    this.reload();
  }
};
</script>
