import router from "./router";
import "core-js/stable";
import "./plugins";
import "./config";
import "vuetify-dialog/dist/vuetify-dialog.css";

import App from "./App.vue";

import Vue from "vue";
import VuetifyDialog from "vuetify-dialog";
// import cssVars from "css-vars-ponyfill";
import i18n from "./plugins/i18n";
import store from "./store";
import vuetify from "./plugins/vuetify";
import get from "lodash/get";
import ebsn from "~/functions";
import blocks from "@/components/categoryBlock/categoryBlock";
import SmartBanner from "smart-app-banner";

import { showToaster, showConfirm, showNotification } from "~/notifications";
import "smart-app-banner/dist/smart-app-banner.css";

import "./scss/app.scss";
if (navigator.platform == "Win32") import("./scss/scrollbar.scss");

Vue.config.productionTip = false;

// cssVars({});

global.EventBus = new Vue({
  i18n
});

Vue.use(VuetifyDialog, {
  context: {
    vuetify,
    store,
    router,
    i18n
  },
  confirm: {
    actions: {
      false: {
        text: "No",
        color: "primary",
        outline: true
      },
      true: {
        text: "Si",
        color: "primary"
      }
    },
    icon: true
  }
});
Vue.use(ebsn);
Vue.use(blocks);

Vue.mixin({
  data() {
    // eslint-disable-next-line no-debugger
    return {
      cordovaPlatform: typeof process.env.CORDOVA_PLATFORM != "undefined",
      isCordova: typeof process.env.CORDOVA_PLATFORM != "undefined"
    };
  },
  computed: {
    //@deprecated use isCordova instead
    $platform_is_cordova: () => typeof cordova !== "undefined",
    site() {
      let result = get(this, "$route.meta.site", "ecommerce");
      return result;
    }
  },
  methods: {
    geti18nObject(path) {
      let obj = path.split(".").reduce((o, i) => {
        if (o && typeof o[i] !== "undefined") return o[i];
        else 0;
      }, this.$i18n.messages[this.$i18n.fallbackLocale]);
      return obj;
      // get lenght -> Object.keys(obj).length;
    }
  }
});

Vue.prototype.$dialog.on("shown", ({ dialog }) => {
  global.config.dialogs.push(dialog);
});

Vue.prototype.$dialog.on("destroyed", ({ dialog }) => {
  global.config.dialogs = global.config.dialogs.filter(
    _dialog => _dialog.id !== dialog.id
  );
});

Vue.prototype.$dialog.clearDialogs = () => {
  global.config.dialogs.forEach(_dialog => _dialog.remove());
  global.config.dialogs = [];
};

const vm = new Vue({
  router,
  store,
  vuetify,
  i18n,
  created() {
    if (!process.env.VUE_APP_CORDOVA_PLATFORM) {
      new SmartBanner({
        daysHidden: 7, // days to hide banner after close button is clicked (defaults to 15)
        daysReminder: 14, // days to hide banner after "VIEW" button is clicked (defaults to 90)
        appStoreLanguage: "it", // language code for the App Store (defaults to user's browser language)
        title: process.env.VUE_APP_STORE_TITLE,
        author: "",
        button: "Installa",
        store: {
          ios: "Scarica l'app",
          android: "Scarica l'app"
        },
        price: {
          ios: "",
          android: ""
        },
        icon: "/img_layout/app_icon.png"
        // , force: 'ios' // Uncomment for platform emulation
      });
    }
  },
  render: h => h(App)
}).$mount("#app");

global.vm = vm;

const toastedOption = {
  position: "top-center",
  duration: 3000,
  iconPack: "mdi",
  theme: "bubble",
  keepOnHover: true,
  top: true,
  action: {
    icon: "$clear",
    onClick: (e, toastObject) => {
      toastObject.goAway(0);
    }
  }
};
global.EventBus.$on("error", payload => {
  showToaster("error", payload, toastedOption);
});
global.EventBus.$on("warning", payload => {
  showToaster("warning", payload, toastedOption);
});
global.EventBus.$on("success", payload => {
  showToaster("success", payload, toastedOption);
});

global.EventBus.$on("confirm", showConfirm);

global.EventBus.$on("notification", showNotification);

global.EventBus.$on("toggleModal", isModalOpened => {
  let cartRightDrawer = document.getElementById("cart-right-drawer");
  let wInnerHeight = window.innerHeight;
  cartRightDrawer.style.height = wInnerHeight + "px";
  if (isModalOpened) {
    if (document.body.style.position != "fixed") {
      let scrollY = "-" + parseInt(window.scrollY) + "px";
      document.body.classList.add("modal-open");
      document.body.style.position = "fixed";
      document.body.style.top = scrollY;
    }
  } else {
    document.body.style.position = "";
    document.body.classList.remove("modal-open");
    let bodyTop = document.body.style.top.replace("px", "");
    let numberY = Math.abs(parseInt(bodyTop));
    document.body.style.top = "";
    window.scrollTo(0, numberY);
  }
});

window.addEventListener(
  "resize",
  function() {
    let cartRightDrawer = document.getElementById("cart-right-drawer");
    let wInnerHeight = window.innerHeight;
    cartRightDrawer.style.height = wInnerHeight + "px";
  },
  true
);

window.handleOpenURL = function(url) {
  setTimeout(() => {
    console.log("::::: window.handleOpenURL ::::: ", url);
    console.log("::::: window.handleOpenURL store ::::: ", store);
    store.dispatch("handleOpenUrl", {
      url: url
    });
  });
};
