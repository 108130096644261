var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-list',{staticClass:"category-tree"},[_c('v-list-item',{staticClass:"hidden-md-and-up"},[_c('v-list-item-title',{staticClass:"primary--text text-h2"},[_vm._v(" "+_vm._s(_vm.$t("categories.title"))+" ")]),_c('v-list-item-action',{on:{"click":function($event){$event.preventDefault();$event.stopPropagation();_vm.drawerLeft = !_vm.drawerLeft}}},[_c('v-icon',[_vm._v("$close")])],1)],1),_vm._l((_vm.allCategories),function(category){return _c('v-list-group',{key:category.categoryId,staticClass:"category1",class:[
      {
        'category-custom': category.isCustom
      },
      `category-${category.categoryId}`
    ],attrs:{"append-icon":""},on:{"click":function($event){return _vm.goToCategory(category)}},scopedSlots:_vm._u([{key:"prependIcon",fn:function(){return [_c('ebsn-img',{attrs:{"width":"24","height":"24","target":category,"path":"category_info.ICON","alt":`Icona Categoria ${category.name}`}})]},proxy:true},{key:"activator",fn:function(){return [_c('v-list-item-title',{staticClass:"font-weight-bold text-font-big",style:({ color: _vm.$ebsn.meta(category, 'category_info.COLOR') })},[_c('router-link',{attrs:{"to":{ name: 'Category', params: { pathMatch: category.slug } },"event":""}},[_vm._v(" "+_vm._s(category.name)+" ")])],1)]},proxy:true}],null,true)},[_vm._l((category.categories),function(category2){return [(category2.categories)?_c('v-list-group',{key:category2.categoryId,staticClass:"category2",attrs:{"sub-group":"","prepend-icon":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item',{staticClass:"px-0",attrs:{"to":{
              name: 'Category',
              params: { pathMatch: category2.slug }
            }},on:{"click":function($event){return _vm.subToggle(category2.categoryId)}}},[_c('v-list-item-title',{staticClass:"category2-title pl-8",style:({ color: _vm.$ebsn.meta(category2, 'category_info.COLOR') })},[_vm._v(" "+_vm._s(category2.name)+" ")])],1)]},proxy:true}],null,true),model:{value:(_vm.subActive[category2.categoryId]),callback:function ($$v) {_vm.$set(_vm.subActive, category2.categoryId, $$v)},expression:"subActive[category2.categoryId]"}},_vm._l((category2.categories),function(category3){return _c('v-list-item',{key:category3.categoryId,staticClass:"category3",attrs:{"to":{ name: 'Category', params: { pathMatch: category3.slug } },"color":"grey lighten-2"}},[_c('v-list-item-title',{style:({ color: _vm.$ebsn.meta(category3, 'category_info.COLOR') })},[_vm._v(" "+_vm._s(category3.name)+" ")])],1)}),1):_c('v-list-item',{key:category2.categoryId,staticClass:"category2 no-child pl-8",attrs:{"link":"","to":{ name: 'Category', params: { pathMatch: category2.slug } },"color":"grey lighten-2"},on:{"click":function($event){return _vm.subToggle(category2.categoryId)}}},[_c('v-list-item-title',{style:({ color: _vm.$ebsn.meta(category2, 'category_info.COLOR') })},[_vm._v(_vm._s(category2.name))])],1)]}),(_vm.categoryOpened)?_c('category-block',{staticClass:"category-block category-block-menu",attrs:{"target":_vm.categoryOpened,"position":"menu","container":false}}):_vm._e()],2)}),(_vm.isCordova)?_c('v-list',[_c('div',{staticClass:"bottom"})]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }