// import { handleCategoryNavigation } from "./routeFunctions";

let routes = [
  {
    path: "/checkout",
    name: "Checkout",
    meta: {
      requiresAuth: true,
      site: "ecommerce",
      slug: "checkout"
    },
    component: () => import("@/views/checkout/Checkout.vue")
  },
  {
    path: "/payment",
    name: "Payment",
    meta: {
      requiresAuth: true,
      site: "ecommerce",
      slug: "payment"
    },

    component: () => import("@/views/checkout/Payment.vue")
  },
  {
    path: "/cart/payment-error/:orderId",
    name: "PaymentError",
    meta: {
      requiresAuth: true,
      site: "ecommerce",
      slug: "cart/payment-error"
    },

    component: () => import("@/views/checkout/PaymentCompleted.vue")
  },
  {
    path: "/cart/payment-error-melaregalo/:orderId",
    name: "PaymentErrorMelaregalo",
    meta: {
      site: "ecommerce",
      slug: "cart/payment-error"
    },
    component: () => import("@/views/checkout/PaymentCompletedMelaregalo.vue")
  },
  {
    path: "/cart/payment-error--melaregalo/:orderId",
    name: "PaymentErrorMelaregaloW",
    meta: {
      site: "ecommerce",
      slug: "cart/payment-error"
    },
    component: () => import("@/views/checkout/PaymentCompletedMelaregalo.vue")
  },
  {
    path: "/cart/payment-completed/:orderId",
    name: "PaymentCompleted",
    meta: {
      requiresAuth: true,
      site: "ecommerce",
      slug: ["payment-completed", "cart/payment-completed"]
    },

    component: () => import("@/views/checkout/PaymentCompleted.vue")
  },
  {
    path: "/cart/payment-completed-melaregalo/:orderId",
    name: "PaymentCompletedMelaregalo",
    meta: {
      site: "ecommerce",
      slug: ["payment-completed", "cart/payment-completed"]
    },

    component: () => import("@/views/checkout/PaymentCompletedMelaregalo.vue")
  }
];

export default routes;
