<template>
  <v-card
    :to="news.navTo"
    class="news-banner-card d-flex secondary"
    @click="clicked"
  >
    <!-- <v-card-title>{{ news.title }}</v-card-title> -->
    <v-card-text>
      <span class="news-content" v-html="news.descr"></span>
    </v-card-text>
    <v-card-actions class="pa-1">
      <v-btn
        aria-label="Ho capito"
        depressed
        icon
        color="primary"
        @click.stop.prevent="markAsRead()"
      >
        <v-icon>$close</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<style lang="scss">
.news-banner-card {
  &.v-sheet.v-card {
    border-radius: 0px;
    .v-card__text {
      font-size: 14px;
      text-align: center;
    }
  }
}
</style>
<script>
import clickHandler from "~/mixins/clickHandler";

import newsService from "~/service/newsService";

export default {
  name: "NewsBanner",
  mixins: [clickHandler],
  props: {
    news: { type: Object, required: true }
  },
  computed: {
    showNewsFeed() {
      return this.newsFeed.length > 0;
    }
  },
  methods: {
    async markAsRead() {
      await newsService.markAsRead(this.news.newsId);
      this.$emit("remove", this.news.newsId);
    }
  }
};
</script>
