// import { handleCategoryNavigation } from "./routeFunctions";
import base from "./base";
import wordpress from "./wordpress";
import catalog from "./catalog";
import profile from "./profile";
import checkout from "./checkout";

const routes = [
  {
    path: "/",
    name: "HomeSite",
    meta: {
      site: "site",
      skipCategory: true,
      canonical: "/"
    },
    props: true,
    component: () => import("@/views/HomeSite.vue")
  },
  {
    path: "/spesa-online-tigros",
    name: "Home",
    meta: {
      site: "ecommerce",
      slug: "spesa-online-tigros"
    },
    component: () => import("@/views/Home.vue")
  },
  {
    path: "/shop",
    name: "Home",
    meta: {
      site: "ecommerce",
      slug: "spesa-online-tigros"
    },
    component: () => import("@/views/Home.vue")
  },
  {
    path: "/melaregalo",
    name: "Melaregalo",
    meta: {
      site: "site",
      slug: "melaregalo"
    },
    component: () => import("@/views/catalog/Melaregalo.vue")
  },
  ...base,
  ...wordpress,
  ...profile,
  ...checkout,
  ...catalog
];

export default routes;
