<template>
  <v-list class="category-tree">
    <v-list-item class="hidden-md-and-up">
      <v-list-item-title class="primary--text text-h2">
        {{ $t("categories.title") }}
      </v-list-item-title>
      <v-list-item-action @click.prevent.stop="drawerLeft = !drawerLeft">
        <v-icon>$close</v-icon>
      </v-list-item-action>
    </v-list-item>
    <v-list-group
      v-for="category in allCategories"
      :key="category.categoryId"
      class="category1"
      :class="[
        {
          'category-custom': category.isCustom
        },
        `category-${category.categoryId}`
      ]"
      append-icon=""
      @click="goToCategory(category)"
    >
      <template v-slot:prependIcon>
        <ebsn-img
          width="24"
          height="24"
          :target="category"
          path="category_info.ICON"
          :alt="`Icona Categoria ${category.name}`"
        />
      </template>
      <template v-slot:activator>
        <v-list-item-title
          class="font-weight-bold text-font-big"
          :style="{ color: $ebsn.meta(category, 'category_info.COLOR') }"
        >
          <router-link
            :to="{ name: 'Category', params: { pathMatch: category.slug } }"
            event
          >
            {{ category.name }}
          </router-link>
        </v-list-item-title>
      </template>
      <template v-for="category2 in category.categories">
        <v-list-group
          v-model="subActive[category2.categoryId]"
          sub-group
          class="category2"
          v-if="category2.categories"
          :key="category2.categoryId"
          prepend-icon=""
        >
          <template v-slot:activator>
            <v-list-item
              @click="subToggle(category2.categoryId)"
              class="px-0"
              :to="{
                name: 'Category',
                params: { pathMatch: category2.slug }
              }"
            >
              <v-list-item-title
                class="category2-title pl-8"
                :style="{ color: $ebsn.meta(category2, 'category_info.COLOR') }"
              >
                {{ category2.name }}
              </v-list-item-title>
            </v-list-item>
          </template>
          <v-list-item
            v-for="category3 in category2.categories"
            :key="category3.categoryId"
            :to="{ name: 'Category', params: { pathMatch: category3.slug } }"
            color="grey lighten-2"
            class="category3"
          >
            <v-list-item-title
              :style="{ color: $ebsn.meta(category3, 'category_info.COLOR') }"
            >
              {{ category3.name }}
            </v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-list-item
          v-else
          @click="subToggle(category2.categoryId)"
          link
          :to="{ name: 'Category', params: { pathMatch: category2.slug } }"
          color="grey lighten-2"
          class="category2 no-child pl-8"
          :key="category2.categoryId"
        >
          <v-list-item-title
            :style="{ color: $ebsn.meta(category2, 'category_info.COLOR') }"
            >{{ category2.name }}</v-list-item-title
          >
        </v-list-item>
      </template>
      <!-- sidebox banner -->

      <category-block
        v-if="categoryOpened"
        :target="categoryOpened"
        position="menu"
        class="category-block category-block-menu"
        :container="false"
      />
    </v-list-group>
    <v-list v-if="isCordova"><div class="bottom"></div></v-list>
  </v-list>
</template>
<script>
// import CategoryBlock from "@/components/categoryBlock/CategoryBlock.vue";
import deliveryReactive from "~/mixins/deliveryReactive";

import categoryService from "@/commons/service/categoryService";

import { mapState, mapActions, mapGetters } from "vuex";
import each from "lodash/each";

export default {
  name: "CategoryTree",
  // components: { CategoryBlock },
  mixins: [deliveryReactive],
  data() {
    return {
      expandedKeys: [],
      selectedKey: null,
      threeCategories: [],
      threeCategoriesBelow: [],
      subActive: {},
      categoryOpened: null,
      firstLevelCategories: []
    };
  },
  computed: {
    ...mapState({
      categories: ({ category }) => category.categoryTree,
      storeDrawerLeft: state => state.app.drawerLeft
    }),
    ...mapGetters({
      isAuthenticated: "cart/isAuthenticated"
    }),
    drawerLeft: {
      get() {
        return this.storeDrawerLeft;
      },
      set(value) {
        this.updatedDrawerLeft(value);
      }
    },
    allCategories() {
      let allCat = [];
      if (this.threeCategories?.children) {
        each(this.threeCategories.children, category => {
          category.isCustom = true;
        });
        allCat = allCat.concat(this.threeCategories.children);
      }
      if (this.categories) {
        allCat = allCat.concat(this.categories);
      }
      if (this.threeCategoriesBelow?.children) {
        each(this.threeCategoriesBelow.children, category => {
          category.isCustom = true;
        });
        allCat = allCat.concat(this.threeCategoriesBelow.children);
      }
      return allCat;
    }
  },
  methods: {
    subToggle(categoryId) {
      for (let i = 0; i < Object.keys(this.subActive).length; i++) {
        if (categoryId != Object.keys(this.subActive)[i]) {
          this.subActive[Object.keys(this.subActive)[i]] = false;
        }
      }
    },
    ...mapActions({
      reload: "category/loadCategoryTree",
      updatedDrawerLeft: "app/updatedDrawerLeft"
    }),
    async fetchCategory(cat) {
      let vm = this;
      if (vm.firstLevelCategories[cat.categoryId] != undefined) {
        vm.categoryOpened = vm.firstLevelCategories[cat.categoryId];
      } else {
        let result = await categoryService.getCategoryBySlug(cat.slug);
        vm.firstLevelCategories[cat.categoryId] = result;
        vm.categoryOpened = result;
      }
    },
    goToCategory(category) {
      this.categoryOpened = null;
      if (category.isCustom) {
        this.$router.push({
          name: "Category",
          params: { pathMatch: category.slug }
        });
      } else {
        this.fetchCategory(category);
      }
    },
    async getCategories() {
      try {
        this.threeCategories = await categoryService.getCategoryBySlug(
          "category-tree",
          false
        );
      } catch (e) {
        console.log(e);
      }
    },
    async getCategoriesBelow() {
      try {
        this.threeCategoriesBelow = await categoryService.getCategoryBySlug(
          "category-tree-below",
          false
        );
      } catch (e) {
        console.log(e);
      }
    }
  },
  created() {
    this.reload();
    this.getCategories();
    this.getCategoriesBelow();
  }
};
</script>
<style lang="scss">
.category-tree {
  .category-custom {
    font-weight: bold;
    text-transform: none !important;
  }

  .category2 {
    &.v-list-group--sub-group .v-list-group__header {
      padding-left: 0px !important;
    }
  }
  .category-block-menu > * {
    padding: 8px 4px 8px 4px;
  }

  a {
    color: inherit !important;
    text-transform: uppercase;
    text-decoration: inherit !important;
  }

  .category1 {
    border-bottom: 1px solid var(--v-grey-lighten2);
    .v-list-item {
      max-height: 50px !important;
      .no-icon {
        min-width: 42px;
        display: inline-block;
      }
    }
    .v-list-item__title {
      font-size: 13px;
      color: $text-color;
      overflow: display !important;
    }
    &.v-list-group:last-child {
      border-bottom: 1px solid transparent;
    }
    &.v-list-group--active {
      .v-list-item__title > a {
        font-weight: 700;
      }
    }
    @media #{map-get($display-breakpoints, 'xs-only')} {
      .v-list-item__icon {
        margin: auto 8px auto 0 !important;
      }
      .v-list-group__header__prepend-icon {
        img {
          max-width: 18px;
          max-height: 18px;
        }
      }
    }
  }
  .category2 {
    padding-left: 22px;
    &.v-list-group--sub-group .v-list-group__header {
      padding-left: 0px !important;
    }
    &.v-list-group--active {
      .category2-title {
        color: $primary !important;
        font-weight: 700;
      }
    }
    &.no-child {
      padding-left: 32px;
      &.v-list-item--active {
        .v-list-item__title {
          color: $primary !important;
          font-weight: 700;
        }
      }

      margin-left: 24px;
    }

    &.v-list-group--sub-group {
      .v-list-item__title > a {
        font-weight: 400;
      }
    }

    .v-list-item--active {
      .v-list-item__title a {
        color: $primary !important;
        font-weight: 700;
      }
    }
  }
  .category3 {
    //padding-left: 48px !important;
    &.v-list-item--active {
      .v-list-item__title {
        color: $text-color !important;
        font-weight: 700 !important;
      }
    }
  }
  .v-list--dense {
    .v-list-item {
      .v-list-item__icon {
        margin: 16px 8px !important;
      }
    }
  }
  .bottom {
    height: 100px;
  }
  @media #{map-get($display-breakpoints, 'xs-only')} {
    // fix for mobile browser, because otherwise with browser's navigation bar opened last category of the tree is not visible
    padding-bottom: 100px;
  }
}
</style>
