import Vue from "vue";
import router from "../router";
import { ApmVuePlugin } from "@elastic/apm-rum-vue";

if (
  !navigator.userAgent.match("Prerender|GTmetrix|Googlebot") &&
  process.env.VUE_APP_CLIENT != "development"
) {
  Vue.use(ApmVuePlugin, {
    router: router,
    config: {
      active: Math.random() < 0.15,
      serviceName: "tigros-spesa-online",
      serverUrl: "https://www.tigros.it:8200/",
      environment: process.env.VUE_APP_CLIENT,
      serviceVersion: global.config.version
    },
    captureErrors: true
  });
}
