<template>
  <v-list class="category-tree site-navigation-tree">
    <v-list-item class="hidden-md-and-up">
      <v-list-item-title class="primary--text text-h2"
        >{{ $t("categories.title") }}
      </v-list-item-title>
      <v-list-item-action @click.prevent.stop="drawerLeft = !drawerLeft">
        <v-icon>$close</v-icon>
      </v-list-item-action>
    </v-list-item>
    <v-list-item :to="{ name: 'Home' }" exact class="secondary lighten-2">
      <v-list-item-title class="primary--text font-weight-bold">
        Spesa Online
        <!-- {{ $t("navbar.siteNavigationDrawer.ecommerce") }} -->
      </v-list-item-title>
    </v-list-item>
    <v-list-item
      v-for="link in siteLinks"
      :key="link.ID"
      :href="link.url"
      @click="clicked"
    >
      <v-list-item-title class="primary--text font-weight-bold">
        {{ link.title }}
      </v-list-item-title>
    </v-list-item>
    <!-- 
    <v-list-item to="/melaregalo">
      <v-list-item-title class="primary--text font-weight-bold">
        Melaregalo
      </v-list-item-title>
    </v-list-item> -->
  </v-list>
</template>
<style lang="scss">
.site-navigation-tree {
  .v-list-item {
    border-bottom: 1px solid var(--v-grey-lighten2);
    text-transform: uppercase;
  }
}
</style>
<script>
import clickHandler from "~/mixins/clickHandler";
import { mapState, mapActions, mapGetters } from "vuex";
import CmService from "~/service/cmService";

export default {
  name: "SiteNavigationDrawer",
  mixins: [clickHandler],
  data() {
    return {
      siteLinks: []
    };
  },
  computed: {
    ...mapState({
      storeDrawerLeft: state => state.app.drawerLeft
    }),
    ...mapGetters({
      isBetaTester: "cart/isBetaTester"
    }),
    drawerLeft: {
      get() {
        return this.storeDrawerLeft;
      },
      set(value) {
        this.updatedDrawerLeft(value);
      }
    }
  },
  methods: {
    async fetchSiteLinks() {
      let res = await CmService.getMenuByLocation("primary?v=2");

      this.siteLinks = res;
      // data => (this.footerLinks = data);
    },
    ...mapActions({
      updatedDrawerLeft: "app/updatedDrawerLeft"
    })
  },
  created() {
    this.fetchSiteLinks();
  }
};
</script>
