const routes = [
  {
    path: "/page/:pageName",
    name: "Page",
    meta: {
      site: "site"
    },
    component: () => import("@/views/wordpress/Page.vue")
  },
  {
    path: "/faq",
    meta: {
      breadCrumb: "Faq",
      site: "site"
    },
    component: {
      render(c) {
        return c("router-view");
      }
    },
    children: [
      {
        name: "FaqCategory",
        path: "",
        meta: {
          site: "site",
          pageName: "faq"
        },
        component: () => import("@/views/wordpress/FaqList.vue")
      },
      {
        path: ":categoryName",
        name: "FaqList",
        meta: {
          breadCrumb: "Dettaglio Faq",
          site: "site"
        },
        component: () => import("@/views/wordpress/FaqList.vue")
      }
    ]
  },
  {
    path: "/vantaggi",
    name: "Vantaggi",
    meta: {
      site: "site"
    },
    component: () => import("@/views/wordpress/Vantaggi.vue")
  },
  {
    path: "/form/:formId",
    name: "NinjaForms",
    meta: {
      site: "site"
    },
    component: () => import("@/commons/components/ninjaForms/NinjaForm.vue"),
    props: true
  },
  {
    path: "/punti-vendita/:warehouseId",
    name: "WarehouseDetail",
    meta: {
      breadCrumb: "Dettaglio Punto Vendita",
      site: "site"
    },
    component: () => import("@/views/store/WarehouseDetail.vue")
  },
  {
    path: "/ristoranti-buongusto/:slug",
    name: "PageBuongusto",
    meta: {
      site: "site"
    },
    component: () => import("@/views/store/PageBuongusto.vue")
  },
  {
    path: "/area-dipendenti",
    name: "Convenzioni",
    meta: {
      site: "site",
      pageName: "private_convenzioni"
    },
    component: {
      render(c) {
        return c("router-view");
      }
    },
    children: [
      {
        name: "ConvenzioniCategory",
        path: "",
        meta: {
          site: "site"
        },
        component: () => import("@/views/wordpress/ConvenzioniList.vue")
      },
      {
        path: ":categoryName",
        name: "ConvenzioniList",
        meta: {
          site: "site"
        },
        component: () => import("@/views/wordpress/ConvenzioniList.vue")
      }
    ]
  }
];

export default routes;
