<template>
  <div class="cart-info-list d-flex pa-4">
    <div class="avatar ma-1 d-sm-none d-md-inline">
      <img
        alt="Logo servizio"
        aria-label="Logo servizio scelto"
        :src="serviceImg"
      />
    </div>
    <div class="d-flex flex-column align-left w-100">
      <div class="d-flex align-center default--text">
        <span
          v-if="isCart"
          class="ml-2 d-none d-sm-inline"
          style="line-height:1"
          >{{ $t("cart.cartInfo.editableAddress") }}
        </span>
        <v-btn
          aria-label="Cambia indirizzo di consegna"
          class="cart-info-button text-none font-weight-bold"
          :class="isCart ? 'text-body-2' : 'text-caption'"
          :disabled="!editableAddress"
          color="primary"
          text
          tile
          small
          elevation="0"
          rounded
          @click="openAddressSelector"
        >
          <span class="info-text text-uppercase font-weight-bold">
            {{ shippingAddress }}
          </span>
          <v-icon right v-if="!isCart">$edit</v-icon>
        </v-btn>
        <!-- icon to be shown in cart drawer -->
        <v-spacer />
        <v-icon
          v-if="isCart"
          aria-label="Cambia indirizzo di consegna"
          color="default"
          class="cart-edit-icon ml-2 d-inline"
          @click="openAddressSelector"
          >$edit</v-icon
        >
      </div>
      <div class="d-flex align-center mt-0 default--text">
        <span
          v-if="isCart"
          class="d-none d-sm-inline ml-2"
          style="line-height:1"
          >{{ $t("cart.cartInfo.timeslot") }}</span
        >
        <v-btn
          aria-label="Cambia orario di consegna"
          class="cart-info-button text-none font-weight-regular"
          :class="isCart ? 'text-body-2' : 'text-caption'"
          :disabled="!editableTimeslot"
          color="primary"
          text
          tile
          small
          elevation="0"
          rounded
          @click="openTimeslotSelector"
        >
          <span class="info-text font-weight-bold">
            {{ timeslot }}
          </span>
          <v-icon right v-if="!isCart">$edit</v-icon></v-btn
        >
        <!-- icon to be shown in cart drawer -->
        <v-spacer />
        <v-icon
          v-if="isCart"
          aria-label="Cambia orario di consegna"
          color="default"
          class="cart-edit-icon"
          @click="openTimeslotSelector"
          >$edit</v-icon
        >
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.cart-info-list {
  position: relative;
  .info-text {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .avatar {
    img {
      height: 36px;
    }
    @media (max-width: 1049px) {
      display: none !important; // override for small screens
    }
  }
  .v-btn.cart-info-button {
    justify-content: left;
    font-size: 12px;
    height: 20px !important;
    letter-spacing: normal;
    max-width: 85%;
    .v-btn__content {
      overflow: hidden;
      text-overflow: ellipsis;
      display: contents;
    }
    .v-icon {
      font-size: $font-size-root;
      height: 14px;
      width: 14px;
    }
  }
}
</style>
<script>
import get from "lodash/get";
import { mapState } from "vuex";
import login from "~/mixins/login";
import cartInfo from "~/mixins/cartInfo";
export default {
  name: "CartInfoList",
  mixins: [login, cartInfo],
  data() {
    return {
      maxWidth: 320,
      maxHeight: 60
    };
  },
  props: {
    isCart: { type: Boolean, required: false },
    isNavbar: { type: Boolean, required: false }
  },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    customAddress() {
      let shippingAddress = get(this.cart, "shippingAddress");
      if (shippingAddress) {
        return `${shippingAddress.addressName} - ${shippingAddress.address1}`;
      }
      return "";
    }
  }
};
</script>
