<template>
  <div class="mr-0 mr-md-5 mt-3 mt-md-0 order-1 order-lg-2">
    <!-- <router-link
      :to="{
        name: 'Page',
        params: { pageName: 'informativa-clienti-e-fornitori' }
      }"
      >{{ $t("footer.links.customerInfo") }}</router-link
    >
    &nbsp;|&nbsp; -->
    <router-link
      :to="{ name: 'Page', params: { pageName: 'privacy-policy' } }"
      >{{ $t("footer.links.privacyPolicy") }}</router-link
    >
    &nbsp;|&nbsp;
    <router-link
      :to="{ name: 'Page', params: { pageName: 'cookie-policy' } }"
      >{{ $t("footer.links.cookiePolicy") }}</router-link
    >
    &nbsp;|&nbsp;
    <a class="cookiebot-widget" @click="openCookieBotWidget">{{
      $t("footer.links.ManageCookiePolicy")
    }}</a>
  </div>
</template>
<script>
export default {
  name: "PrivacyLinks",
  methods: {
    openCookieBotWidget() {
      // eslint-disable-next-line no-undef
      Cookiebot.show();
    }
  }
};
</script>
