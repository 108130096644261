<template>
  <v-card class="cart-summary px-3 text-right" flat v-if="cart.cartId > 0">
    <v-card-text class="default--text">
      <!-- <div class="d-flex justify-end align-top font-weight-bold">
        <span>{{ $t("cartSummary.totalPrice") }}</span>
        <span class="value">
          {{ $n(cart.totalPrice, "currency") }}
        </span>
      </div> -->
      <div class="d-flex justify-end align-top font-weight-bold text-body-1">
        <span>{{ $t("cartSummary.totalPriceVariable") }}</span>
        <span class="value">{{ $n(cart.totalPriceVariable, "currency") }}</span>
      </div>
      <div class="d-flex justify-end">
        <div
          class="text-h3 d-flex justify-end align-center font-weight-bold text-uppercase"
        >
          <span>{{ $t("cartSummary.grossTotal") }}</span
          ><span class="value">{{ $n(cart.totalPrice, "currency") }}</span>
        </div>
      </div>
    </v-card-text>
    <v-card-actions class="justify-end">
      <v-row no-gutters>
        <v-col :cols="cart.totalItems > 0 ? 5 : 12" class="pa-1">
          <v-btn
            x-large
            color="secondary"
            class="primary--text"
            depressed
            min-width="95%"
            v-on:click="fastPayment"
            v-if="!full"
          >
            <span>{{ $t("cart.button.fastPayment") }}</span>
            <v-icon small class="ml-1">$fastSearch</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="7" class="pa-1" v-if="cart.totalItems > 0">
          <v-btn
            class="checkout-btn"
            to="/checkout"
            color="primary"
            min-width="95%"
            x-large
            depressed
            >{{ $t("cart.button.goToPayment") }}</v-btn
          >
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
  <v-card v-else>{{ $t("no-cart") }}</v-card>
</template>
<style lang="scss">
.cart-summary {
  .value {
    min-width: 150px;
  }
  @media #{map-get($display-breakpoints, 'xs-only')} {
    .v-card__actions {
      padding: 8px 0;
    }
  }
}
</style>
<script>
import FastPayment from "@/components/cart/FastPayment.vue";

import { mapState, mapActions } from "vuex";

export default {
  name: "CartSummary",
  props: ["full"],
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    })
  },
  methods: {
    ...mapActions({
      emptyCart: "cart/emptyCart",
      updatedDrawerRight: "app/updatedDrawerRight",
      removeGiftCode: "cart/removeGiftCode"
    }),
    async emptyCartConfirm() {
      if (this.cart.totalItems > 0) {
        let res = await global.vm.$dialog.confirm({
          text: global.vm.$t("message.emptyCart")
        });
        if (res) {
          this.emptyCart();
        }
      }
    },
    async fastPayment() {
      let _this = this;
      let res = await _this.$dialog.show(FastPayment, {
        waitForResult: true,
        width: 500
      });
      if (res) {
        // let items = [];
        // forEachCartItem(_this.cart, function(item) {
        //   items.push(item);
        // });
        // await ListService.addProductsToList(res.listId, items);
      }
    },
    closeDrawer() {
      this.updatedDrawerRight(false);
    },
    removeCode(giftCodeId) {
      this.removeGiftCode(giftCodeId);
    },
    changeService() {},
    changeTimeslot() {},
    changeDelivery() {}
  }
};
</script>
