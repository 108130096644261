<template>
  <v-card
    flat
    class="cart-info-wrapper d-flex align-center primary--text grey lighten-3 px-4 py-3"
  >
    <div>
      <v-img height="44" width="55" contain :src="serviceImage"></v-img>
    </div>
    <div class="w-100">
      <v-card-actions class="py-1" @click="openAddressSelector">
        <v-card-text class="py-0 px-1">
          <span class="hidden-xs-only">Ritiro presso: </span>
          <strong>{{ shippingAddress }}</strong>
        </v-card-text>
        <v-icon small color="primary">$edit</v-icon>
      </v-card-actions>
      <v-card-actions class="mt-1 py-1" @click="openTimeslotSelector">
        <v-card-text class="py-0 px-1">
          <span class="hidden-xs-only">Data e ora: </span>
          <strong class="text-uppercase" v-html="timeslot"></strong>
        </v-card-text>
        <v-icon small color="primary">$edit</v-icon>
      </v-card-actions>
    </div>
  </v-card>
</template>
<style lang="scss">
.cart-info-wrapper {
  strong {
    font-family: $heading-font-family;
  }
  .v-card__text {
    padding-top: 0px !important;
  }
}
</style>
<script>
// import CartInfoAddressCard from "./CartInfoAddressCard.vue";
// import CartInfoTimeslotCard from "./CartInfoTimeslotCard.vue";
import cartInfo from "~/mixins/cartInfo";
import login from "~/mixins/login";

export default {
  name: "CartInfoWrapper",
  // components: { CartInfoAddressCard, CartInfoTimeslotCard },
  mixins: [cartInfo, login],
  data() {
    return {};
  },
  computed: {
    serviceImage() {
      return this.$t(
        "navbar.service." +
          this.cart.shippingAddress.deliveryServiceId +
          ".icon"
      );
    }
  }
};
</script>
